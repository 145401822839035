/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../../components/layout';
import Hero from '../../components/hero';
import Article from '../../components/article'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import { graphql } from 'gatsby'
import { Stack } from 'raam'
import { EventCTA } from '../../components/tickets'
import MetaData from '../../components/metadata'

const ArticlePage = (props) => {

  const page = props.pageContext.variant === 'season' ? props.data?.contentfulSeasonTickets : props.data?.contentfulMembershipTickets;

  return (
    <Layout 
      {...props} 
      light={page?.theme === 'light'}
    >
      <MetaData title={page?.title} />
      <Hero 
        data={page?.hero} 
        strap={page?.strap}
        variant={page?.theme}
        sx={{mb: 6}}
        back='/tickets'
      />

      <Stack gap={4} mb={12}>
        {page?.article &&
          <Wrapper>
            <Inner>
              <Article 
                {...page.article} 
                forceAside
                asideBefore={(
                  <EventCTA
                    variant={props.pageContext.variant}
                    label={`Buy ${props.pageContext.variant === 'season' ? 'Season' : 'Membership'} Tickets`}
                  />
                )}
              />
            </Inner>
          </Wrapper>
        }
      </Stack>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TicketArticles {
    contentfulSeasonTickets {
      title
      hero {
        ...HeroFragment
      }
      article {
        title
        main {
          raw
          references {
            ...ArticleFragment
          }
        }
        aside {
          raw
          references {
            ...ArticleFragment
          }
        }
      }
    }
    contentfulMembershipTickets {
      title
      hero {
        ...HeroFragment
      }
      article {
        title
        main {
          raw
          references {
            ...ArticleFragment
          }
        }
        aside {
          raw
          references {
            ...ArticleFragment
          }
        }
      }
    }
  }
`

export default ArticlePage